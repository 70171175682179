@font-face {
  font-family: 'connex-v4-icons';
  src: url('../fonts/connex-v4-icons.woff') format('woff');
}
.connex-v4-icon {
  vertical-align: middle;
  text-align: center;
  font-family: 'connex-v4-icons' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.connex-v4-icon.silo:before {
  content: '\ea01';
}
.connex-v4-icon.factory:before {
  content: '\ea02';
}
.connex-v4-icon.foundation:before {
  content: '\ea03';
}
.connex-v4-icon.content_paste_search:before {
  content: '\ea04';
}
.connex-v4-icon.connex-v4-icon.rule:before {
  content: '\ea05';
}
.connex-v4-icon.local_offer:before {
  content: '\ea06';
}
.connex-v4-icon.inventory_2:before {
  content: '\ea07';
}
.connex-v4-icon.category:before {
  content: '\ea08';
}
.connex-v4-icon.scale:before {
  content: '\ea09';
}
.connex-v4-icon.Group207:before {
  content: '\ea0a';
}
.connex-v4-icon.space_bar:before {
  content: '\ea0b';
}
.connex-v4-icon.track_changes:before {
  content: '\ea0c';
}
.connex-v4-icon.rv_hookup:before {
  content: '\ea0d';
}
.connex-v4-icon.warehouse:before {
  content: '\ea0e';
}
.connex-v4-icon.api:before {
  content: '\ea0f';
}
.connex-v4-icon.segment:before {
  content: '\ea10';
}
.connex-v4-icon.account_tree:before {
  content: '\ea11';
}
.connex-v4-icon.home_work:before {
  content: '\ea12';
}
.connex-v4-icon.token:before {
  content: '\ea13';
}
.connex-v4-icon.price_change:before {
  content: '\ea14';
}
.connex-v4-icon.contact_page:before {
  content: '\ea15';
}
.connex-v4-icon.payments:before {
  content: '\ea16';
}
.connex-v4-icon.steeringwheel:before {
  content: '\ea17';
}
.connex-v4-icon.science:before {
  content: '\ea18';
}
.connex-v4-icon.OrderIcon:before {
  content: '\ea19';
}
.connex-v4-icon.payment:before {
  content: '\ea1a';
}
.connex-v4-icon.storefront:before {
  content: '\ea1b';
}
.connex-v4-icon.contacts:before {
  content: '\ea1c';
}
.connex-v4-icon.psychology:before {
  content: '\ea1d';
}
.connex-v4-icon.local_shipping:before {
  content: '\ea1e';
}
.connex-v4-icon.business:before {
  content: '\ea1f';
}
.connex-v4-icon.settings_input_component:before {
  content: '\ea20';
}
.connex-v4-icon.layers:before {
  content: '\ea21';
}
.connex-v4-icon.free_cancellation:before {
  content: '\ea22';
}
.connex-v4-icon.notifications:before {
  content: '\ea23';
}
.connex-v4-icon.vehicle_type:before {
  content: '\ea24';
}
.connex-v4-icon.dashboard_sidebar:before {
  content: '\ea25';
}
.connex-v4-icon.company_sidebar:before {
  content: '\ea26';
}
.connex-v4-icon.order_sidebar:before {
  content: '\ea27';
}
.connex-v4-icon.tracking_sidebar:before {
  content: '\ea28';
}
.connex-v4-icon.Group2608599:before {
  content: '\ea29';
}
.connex-v4-icon.billing_sidebar:before {
  content: '\ea2a';
}
.connex-v4-icon.Group2608600:before {
  content: '\ea2b';
}
.connex-v4-icon.planning_sidebar:before {
  content: '\ea2c';
}
.connex-v4-icon.report_sidebar:before {
  content: '\ea2d';
}
.connex-v4-icon.setup_sidebar:before {
  content: '\ea2e';
}
.connex-v4-icon.Group2608598:before {
  content: '\ea2f';
}
